<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">404</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PageNotFound',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>